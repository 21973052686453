import React, { useEffect, useState } from "react";
import User from "../Component/User";
import RecentUsers from "../Component/RecentUsers";
import RecentOrders from "../Component/RecentOrders";
import OrdersSummary from "../Component/OrdersSummary";
import { useSelector } from "react-redux";
import { UserOctagon, TruckFast, Shop } from "iconsax-react";
import { useDispatch } from "react-redux";
import { dashboard } from "../redux/actions/userAuth";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import "rsuite/dist/rsuite.css";

const Dashboard = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderSummary, setOrderSummary] = useState("");
  const [roleChange, setroleChange] = useState("");
  const [data, setData] = useState({
    orderStatus: "",
    startDate: "",
    endDate: "",
  });
  const roleData = [
    { label: "Open", value: "open" },
    { label: "In Stock", value: "in stock" },
    { label: "In Transfer", value: "in transfer" },
    { label: "Sold Out", value: "sold out" },
  ];
  useEffect(() => {
    dispatch(
      dashboard(roleChange, data.orderStatus, data.startDate, data.endDate)
    );
  }, [roleChange]);

  const setStatus = (e) => {
    setData({
      ...data,
      orderStatus: e.target.value,
    });
    dispatch(
      dashboard(roleChange, e.target.value, data.startDate, data.endDate)
    );
  };
  const getDataRange = (e) => {
    const startDate = moment(e[0]);
    const endDate = moment(e[1]);
    const startDateNew = startDate.format("YYYY-MM-DD");
    const endDateNew = endDate.format("YYYY-MM-DD");
    setData({
      ...data,
      startDate: startDateNew,
      endDate: endDateNew,
    });
    dispatch(dashboard(roleChange, data.orderStatus, startDateNew, endDateNew));
  };
  const clearDateRange = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
    });
    dispatch(dashboard(roleChange, data.orderStatus, "", ""));
  };
  useEffect(() => {
    if (status.userDashboard.data !== "") {
      if (status.userDashboard?.data?.status === 200) {
        setOrderSummary(status.userDashboard?.data?.data);
      }
    }
  }, [status]);

  return (
    <>
      <div className="OrdersSummary mr-0">
        <div className="order-summary-section dashboard-filter">
          <ul>
            <li
              className={roleChange === "" ? "active" : ""}
              onClick={() => setroleChange("")}
            >
              All
            </li>
            <li
              className={roleChange === "dealer" ? "active" : ""}
              onClick={() => setroleChange("dealer")}
            >
              Dealer
            </li>
            <li
              className={roleChange === "distributor" ? "active" : ""}
              onClick={() => setroleChange("distributor")}
            >
              Distributor
            </li>
            <li
              className={roleChange === "retailer" ? "active" : ""}
              onClick={() => setroleChange("retailer")}
            >
              Retailer
            </li>
          </ul>
        </div>
        <div className="filter-order dashboard-f">
          <div className="input-filter formSection">
            {" "}
            <div className="formInput formInputSelect">
              <TextField
                id="filled-select-role"
                select
                label="Select Status"
                defaultValue="--Select--"
                name="role"
                SelectProps={{ native: true }}
                variant="filled"
                onChange={(e) => setStatus(e)}
              >
                <option key="" value=""></option>
                {roleData &&
                  roleData.map((option, key) => (
                    <option key={key} value={option.value}>
                      {" "}
                      {option.label}{" "}
                    </option>
                  ))}
              </TextField>
            </div>
          </div>
          <div className="input-filter">
            <DateRangePicker
              placement="auto"
              placeholder="Select Date"
              onOk={(e) => getDataRange(e)}
              onClean={() => clearDateRange()}
            />
          </div>
        </div>
      </div>
      <div className="BoxFlex">
        <div className="DashMainBox">
          <User
            hadd="Total Users"
            num={orderSummary.totalUsers}
            parr="No. of Users Registered"
            icon=<UserOctagon />
            iconBIG=<UserOctagon />
          />
        </div>
        <div className="DashMainBox">
          <User
            hadd="Total Dealers"
            num={orderSummary.totalDealers}
            parr="No. of Dealers"
            icon=<TruckFast />
            iconBIG=<TruckFast />
          />
        </div>
        <div className="DashMainBox">
          <User
            hadd="Total Retailers"
            num={orderSummary.totalRetailers}
            parr="No. of Retailers"
            icon=<Shop />
            iconBIG=<Shop />
          />
        </div>
        <div className="DashMainBox">
          <User
            hadd="Total Distributors"
            num={orderSummary.totalDistributors}
            parr="No. of Distributors"
            icon=<TruckFast />
            iconBIG=<TruckFast />
          />
        </div>
      </div>
      <div className="recentBoxSection">
        <div className="DashMainBox">
          <RecentUsers />
        </div>
        <div className="DashMainBox">
          <RecentOrders />
        </div>
        <div className="DashMainBox">
          <OrdersSummary showFilter={false} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
