import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ArrowLeft2 } from "iconsax-react";
import { allUsers, setSelectedProduct } from "../redux/actions/userAuth";
const Success = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [selectedDealerEmail, setSelectedDealerEmail] = useState("");
  const [selectedDealerRole, setSelectedDealerRole] = useState("");
  const [activeData, setActiveData] = useState("");
  //const location = useLocation();

  //console.log("location.state.id ", location.state.dealerId);
  let param = window.location.href;
  param = param?.split("?")[1]?.split("=")[1];
  console.log(param);

  useEffect(() => {
    const apiParams = { activeData };
    dispatch(allUsers(apiParams));
  }, [activeData]);
  useEffect(() => {
    if (status.assignProduct.data !== "") {
      if (status.assignProduct.data.status === 200) {
        toast.success(status.assignProduct.data.message, {
          position: "bottom-right",
          theme: "colored",
        });
      } else {
        if (status.assignProduct.data.response != null) {
          toast.error(status.assignProduct.data.response.data.error, {
            position: "bottom-right",
            theme: "colored",
          });
        } else {
          toast.error(status.assignProduct.data.message, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
    }

    if (status.setSelectedProduct.data.length > 0) {
      setSelectedProductList(status.setSelectedProduct.data);
    }

    if (status.allUsers.data !== "") {
      console.log("!!!!user", status.allUsers.data);
      if (status.allUsers.data.status === 200) {
        const filter = status.allUsers?.data?.data.filter(
          (item) => item.userId == param
        );
        setSelectedDealer(filter[0]?.name);
        setSelectedDealerEmail(filter[0]?.email);
        setSelectedDealerRole(filter[0]?.role);
      }
    }
  }, [status]);
  const backBtn = () => {
    dispatch(setSelectedProduct([]));
    window.location.reload();
  };

  useEffect(() => {
    if (status.setSelectedProduct?.data?.length < 1) {
      let path = `/dashboard/alluser-list`;
      navigate(path);
    }
  }, [status]);
  return (
    <>
      <h4>
        <Link to="#" onClick={backBtn}>
          <ArrowLeft2 /> Back to Product Qr Code
        </Link>
      </h4>
      <div className="MainCreateWarip">
        <div className="SuccessADD">
          <div className="SuccessADDbg">
            <h6>Assign Product to Dealer</h6>
            <div className="loginText">
              <div className="textSection SuccessSec">
                <img src="../image/success.png" alt="success" />
                <h2>Success</h2>
                <p className="AllPaddBg">Product Assigned Successfully</p>
              </div>
              <div className="dealerdetail">
                <div className="imageinfo">
                  <img src="../image/truck.png" alt="success" />
                </div>
                <div className="dealerinfo">
                  <div className="dealertext">
                    <p>{selectedDealer}</p>
                  </div>

                  <div className="nameemail">
                    <div>
                      <p> {selectedDealerRole}</p>
                    </div>

                    <div className="cominfo">
                      <p> {selectedDealerEmail}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {selectedProductList.map((renderProductItem) => (
                  <div className="idflex btop">
                    <>
                      <div
                        className="idflexleft"
                        key={renderProductItem.product_id}
                      >
                        <div className="checkboxflex">
                          <h2>
                            <Link
                              to={`/dashboard/Qr?id=${renderProductItem?.product_id}`}
                              data-value={renderProductItem?.id}
                            >
                              SKU ID {renderProductItem?.itemQRID}
                            </Link>
                          </h2>
                          <p>
                            Batch No. {renderProductItem.batchNo}
                            <span>{renderProductItem?.Quantity} 1 item</span>
                            {/* <span>{renderProductItem?.Location}</span> */}
                          </p>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
