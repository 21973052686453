import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import {
  allUsers,
  setSelectedProduct,
  assignProduct,
} from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { ArrowLeft2 } from "iconsax-react";

const AssignQr = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state);
  const [activeData, setActiveData] = useState("");
  const [selectedRole, setSelectedRole] = useState("dealer");
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [selectedDealer, setSelectedDealer] = useState("");
  const [user, setUser] = useState([]);

  const [filterUserList, setFilterUserList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);

  //permission granted//
  const [locationPermissionGranted, setLocationPermissionGranted] =
    useState(false);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);

  function success(pos) {
    const crd = pos.coords;
    const lat = crd.latitude.toString();
    const long = crd.longitude.toString();
    setLatitude(lat);
    setLongitude(long);
  }
  const errors1 = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    toast.error(err.message, {
      position: "bottom-right",
      theme: "colored",
    });
  };

  // useEffect(() => {
  //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //     if (result.state === "granted") {
  //       setLocationPermissionGranted(true);
  //       navigator.geolocation.getCurrentPosition(success);
  //     } else if (result.state === "prompt") {
  //       setShowPermissionPopup(true);
  //       navigator.geolocation.getCurrentPosition(success);
  //     } else {
  //       setLocationPermissionGranted(false);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });

        if (result.state === "granted") {
          setLocationPermissionGranted(true);
          navigator.geolocation.getCurrentPosition(success, errors1);
        } else if (result.state === "prompt") {
          setShowPermissionPopup(true);
          navigator.geolocation.getCurrentPosition(success, errors1);
        } else {
          setLocationPermissionGranted(false);
        }

        result.onchange = () => {
          if (result.state === "granted") {
            setLocationPermissionGranted(true);
            navigator.geolocation.getCurrentPosition(success, errors1);
          } else if (result.state === "denied") {
            setLocationPermissionGranted(false);
          }
        };
      } catch (err) {
        console.error("Error checking geolocation permission:", err);
      }
    };

    checkPermission();
  }, []);

  const handlePermissionRequest = async () => {
    const getCurrentPositionPromise = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    try {
      await getCurrentPositionPromise();
      setLocationPermissionGranted(true);
      setShowPermissionPopup(false);
    } catch (error) {
      console.error("Error requesting location permission:", error);
      toast.error(error.message, {
        position: "bottom-right",
        theme: "colored",
      });
      setLocationPermissionGranted(false);
      setShowPermissionPopup(false);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetForm,
  } = useForm({ defaultValues: { oldPassword: "", newPassword: "" } });

  //assign//

  const assignButtonClick = async () => {
    try {
      if (!selectedDealer) {
        alert("Please select Dealer");
        return;
      }

      let selectedProduct = [];
      selectedProductList.forEach((item) => {
        selectedProduct.push(item.product_id);
      });
      // console.log(`longitude ======>>: `, longitude);
      // console.log(`latitude =======>>: `, latitude);
      const body = {
        product_id: selectedProduct,
        ordered_by: parseInt(selectedDealer),
        lon: longitude,
        lat: latitude,
      };
      if (longitude && latitude) {
        dispatch(assignProduct(body));
      }
      resetForm();
    } catch (error) {
      console.error("Error assigning products:", error);
      toast.success(error, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (status.assignProduct.data !== "") {
      if (status.assignProduct.data.status === 200) {
        toast.success(status.assignProduct?.data?.data?.Message, {
          position: "top-right",
          theme: "colored",
        });
        setTimeout(() => {
          navigate(`/dashboard/success?Id=${selectedDealer}`);
          //window.location.reload();
        }, 3000);
      }
      if (status.assignProduct.data.data === "Order is released") {
        toast.success(status.assignProduct.data.data, {
          position: "top-right",
          theme: "colored",
        });
      } else {
        if (status.assignProduct.data.response != null) {
          toast.error(status.assignProduct.data.response.data.error, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
    }

    if (status.setSelectedProduct.data.length > 0) {
      setSelectedProductList(status.setSelectedProduct.data);
    }
  }, [status?.assignProduct]);

  useEffect(() => {
    const apiParams = { activeData };
    dispatch(allUsers(apiParams));
  }, [activeData]);

  useEffect(() => {
    if (status.allUsers.data !== "") {
      if (status.allUsers.data.status === 200) {
        setUser(status.allUsers.data.data);
        const filter = status.allUsers.data.data.filter(
          (item) => item.role == selectedRole
        );
        setFilterUserList(filter);
      }
    } else {
      if (status.allUsers.data.response != null) {
        toast.error(
          status.allUsers.data.response.data.error
            ? status.allUsers.data.response.data.error
            : status.allUsers.data.response.data,
          {
            position: "bottom-right",
            theme: "colored",
          }
        );
      } else {
        toast.error(status.allUsers.data.message, {
          position: "bottom-right",
          theme: "colored",
        });
      }
    }
  }, [status]);
  const backBtn = () => {
    dispatch(setSelectedProduct([]));
    window.location.reload();
  };

  useEffect(() => {
    if (status.setSelectedProduct.data.length < 1) {
      let path = `/dashboard/alluser-list`;
      navigate(path);
    }
  }, [status]);
  return (
    <>
      <h4>
        <Link to="#" onClick={backBtn}>
          <ArrowLeft2 /> Back to Product Qr Code
        </Link>
      </h4>
      <div className="generetetext assign-qr" style={{ background: "none" }}>
        {locationPermissionGranted ? (
          <div className="mainBg mainBgnew">
            <div className="product">
              <div className="SuccessADDbg">
                <h6>Assign Product to Dealer</h6>
                <form onSubmit={handleSubmit(assignButtonClick)}>
                  <div className="formSection">
                    <div className="formInput formInputSelect">
                      <TextField
                        id="filled-select-role"
                        select
                        label="Select User"
                        defaultValue="Select User"
                        name="user"
                        SelectProps={{ native: true }}
                        variant="filled"
                        value={selectedDealer}
                        onChange={(e) => {
                          setSelectedDealer(e.target.value);
                        }}
                      >
                        <option key="" value="Select User"></option>
                        {filterUserList.map((filteredName) => (
                          <option
                            key={filteredName.userId}
                            value={filteredName.userId}
                          >
                            {filteredName.name}
                          </option>
                        ))}
                      </TextField>
                    </div>
                    {/* <Link to={"/dashboard/success"}>
                      <button type="submit" disabled={!selectedDealer}>
                        Assign
                      </button>
                    </Link> */}
                    {/* <Link
                      to={`/dashboard/success?dealerName=${encodeURIComponent(
                        selectedDealer
                      )}`}
                    >
                      <button type="submit" disabled={!selectedDealer}>
                        Assign
                      </button>
                    </Link> */}
                    <button type="submit" disabled={!selectedDealer}>
                      Assign
                    </button>
                  </div>
                </form>

                {/* {selectedProductList.map((renderProductItem)=>)} */}

                {selectedProductList.map((renderProductItem) => (
                  <div
                    className="idflex btop"
                    key={renderProductItem.product_id}
                  >
                    <>
                      <div className="idflexleft">
                        <div className="checkboxflex">
                          <h2>
                            <Link
                              to={`/dashboard/Qr?id=${renderProductItem?.product_id}`}
                              data-value={renderProductItem?.id}
                            >
                              SKU ID #{renderProductItem?.itemQRID}
                            </Link>
                          </h2>
                          <p>
                            Batch No. #{renderProductItem.batchNo}
                            <span>{renderProductItem?.Quantity} items</span>
                            <span>{renderProductItem?.Location}</span>
                          </p>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : showPermissionPopup ? (
          <div>
            <p>Please allow location permission to view this content.</p>
          </div>
        ) : (
          <div>
            <p>Location permission denied. Cannot view this content.</p>
            <button onClick={handlePermissionRequest}>
              Allow Location Access
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default AssignQr;
