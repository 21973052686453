import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { recentQr } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProduct, logout } from "../redux/actions/userAuth";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Filter } from "iconsax-react";

const AllUserList = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allproduct, setAllProduct] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { oldPassword: "", newPassword: "" } });
  const assignClick = () => {
    // Dispatch action to store selected dealer in Redux
    dispatch(setSelectedProduct(checkedItems));
  };

  useEffect(() => {
    if (status.setSelectedProduct.data.length > 0) {
      let path = `/dashboard/assign-qr`;
      navigate(path);
    }
  }, [status]);
  const handleCheckboxChange = (item) => {
    // Toggle the checked state for the itemID
    setCheckedItems((prevCheckedItems) => {
      if (!item) {
        return prevCheckedItems; // Do nothing if item is undefined
      }

      if (prevCheckedItems.includes(item)) {
        return prevCheckedItems.filter((checkedItem) => checkedItem !== item);
      } else {
        return [...prevCheckedItems, item];
      }
    });
  };
  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setCheckedItems(allproduct.map((item) => item));
    if (isCheckAll) {
      setCheckedItems([]);
    }
  };
  useEffect(() => {
    dispatch(recentQr());
  }, []);
  const filterSkuId = (e) => {
    const a = e.target.value;
    dispatch(recentQr(a));
  };
  useEffect(() => {
    if (status.recentQr.data !== "") {
      if (status.recentQr.data.status === 200) {
        setAllProduct(status.recentQr.data.data);
      }
    }
  }, [status]);
  return (
    <>
      <div className="mainBg">
        <form onSubmit={handleSubmit(assignClick)}>
          <div className="product">
            <div className="allproductwrap">
              <div>
                <h3>Recent Generated QR Code</h3>
              </div>
              <div className="allproductbtn">
                <a href="/dashboard/all-product">Generate New QR </a>
                <button
                  type="submit"
                  disabled={checkedItems.length === 0}
                  className={checkedItems.length === 0 ? "disabled" : ""}
                >
                  Assign Product
                </button>
              </div>
            </div>
            <div className="idflex btop filter-skuid">
              <div className="idflexleft">
                <div className="select-box">
                  <input
                    id="select-all"
                    type="checkbox"
                    className="checkbox__input"
                    checked={isCheckAll}
                    onChange={handleSelectAll}
                    disabled={allproduct?.length <= 0 ? "disabled" : ""}
                  />
                  <label className="checkbox select-label" htmlFor="select-all">
                    {" "}
                    <span className="checkbox__inner"></span>
                    Select All
                  </label>
                </div>
              </div>
              <div className="input-filter formSection">
                <div className="formInput">
                  <FormControl variant="filled">
                    <InputLabel htmlFor="input">Filter SKU ID</InputLabel>
                    <FilledInput
                      id="input2"
                      name="skuid"
                      autoComplete="off"
                      onChange={(e) => filterSkuId(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          {" "}
                          <Filter />{" "}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            {allproduct
              .slice()
              .reverse()
              .map((item) => (
                <div className="idflex btop" key={item.product_id}>
                  <div className="idflexleft">
                    <div>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={checkedItems.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                        <span
                          // onClick={assignClick}
                          className="checkbox__inner"
                        ></span>
                      </label>
                    </div>
                    <div className="checkboxflex">
                      <h2>
                        <Link
                          to={`/dashboard/Qr?id=${item?.product_id}`}
                          data-value={item?.id}
                        >
                          SKU ID #{item?.itemQRID}
                        </Link>
                      </h2>
                      <p>
                        Batch No. #{item.batchNo}
                        <span>{item?.Quantity} items</span>
                        <span>{item?.Location}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </form>
      </div>
    </>
  );
};
export default AllUserList;
