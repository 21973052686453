export const user = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_FAILED: "USER_LOGIN_FAILED",
  USER_SIGNUP: "USER_SIGNUP",
  USER_SIGNUP_FAILED: "USER_SIGNUP_FAILED",
  COUNTRY: "COUNTRY",
  COUNTRY_FAILED: "COUNTRY_FAILED",
  STATE: "STATE",
  STATE_FAILED: "STATE_FAILED",
  ROLE: "ROLE",
  ROLE_FAILED: "ROLE_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  LOGOUT: "LOGOUT",
  LOGOUT_FAILED: "LOGOUT_FAILED",
  PROFILE: "PROFILE",
  PROFILE_FAILED: "PROFILE_FAILED",
  PROFILE_UPDATE: "PROFILE_UPDATE",
  PROFILE_UPDATE_FAILED: "PROFILE_UPDATE_FAILED",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_FAILED: "DASHBOARD_FAILED",
  ALL_USERS: "ALL_USERS",
  ALL_USERS_FAILED: "ALL_USERS_FAILED",
  ALL_ORDER: "ALL_ORDER",
  ALL_ORDER_FAILED: "ALL_ORDER_FAILED",
  USER_PROFILE: "USER_PROFILE",
  USER_PROFILE_FAILED: "USER_PROFILE_FAILED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  DEACTIVATE_USER: "DEACTIVATE_USER",
  DEACTIVATE_USER_FAILED: "DEACTIVATE_USER_FAILED",
  USER_PROFILE_UPDATE: "USER_PROFILE_UPDATE",
  USER_PROFILE_UPDATE_FAILED: "USER_PROFILE_UPDATE_FAILED",
  ORDER_DETAILS: "ORDER_DETAILS",
  ORDER_DETAILS_FAILED: "ORDER_DETAILS_FAILED",
  ORDER_ITEM_DETAILS: "ORDER_ITEM_DETAILS",
  ORDER_ITEM_DETAILS_FAILED: "ORDER_ITEM_DETAILS_FAILED",
  CREATE_QR: "CREATE_QR",
  CREATE_QR_FAILED: "CREATE_QR_FAILED",
  RECENT_QR_CODE: "RECENT_QR_CODE",
  RECENT_QR_CODE_FAILED: "RECENT_QR_CODE_FAILED",
  QR_SUCCESS: "QR_SUCCESS",
  QR_FAILED: "QR_FAILED",
  QR_DATA_SUCCESS: "QR_DATA_SUCCESS",
  QR_DATA_FAILED: "QR_DATA_FAILED",
  ASSIGN_PRODUCT: "ASSIGN_PRODUCT",
  ASSIGN_PRODUCT_FAILED: "ASSIGN_PRODUCT_FAILED",
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
  SCANED_DETAILS: "SCANED_DETAILS",
  SCANED_DETAILS_FAILED: "SCANED_DETAILS_FAILED",
  SCANED_DETAILS_ALL: "SCANED_DETAILS_ALL",
  SCANED_DETAILS_ALL_FAILED: "SCANED_DETAILS_ALL_FAILED",
  ORDER_EXPORT: "ORDER_EXPORT",
  ORDER_EXPORT_FAILED: "ORDER_EXPORT_FAILED",
};
