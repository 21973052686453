import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, Box, Location, DocumentUpload } from "iconsax-react";
import { auth } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createQr } from "../redux/actions/userAuth";

const AddProduct = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state);
  const [formData, setFormData] = useState({
    ProductName: "",
    sku_id: "",
    description: "",
    quantity: "",
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name == "quantity" ? parseInt(value) : value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const validateForm = () => {
    const validationErrors = {};
    if (!formData.sku_id) {
      validationErrors.sku_id = "SKU ID is required";
    }
    if (!formData.ProductName) {
      validationErrors.ProductName = "Product Name is required";
    }
    if (!formData.description) {
      validationErrors.description = "Product Description is required";
    }
    if (!formData.quantity) {
      validationErrors.quantity = "Quantity is required";
    }
    if (!selectedFile) {
      validationErrors.selectedFile = "select File is required";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      dispatch(createQr(formData));
    }
  };
  useEffect(() => {
    if (status.createQrReducer?.data !== "") {
      if (status.createQrReducer?.data.status === 200) {
        toast.success(status.createQrReducer?.data?.data, {
          position: "bottom-right",
          theme: "colored",
        });
        setTimeout(() => {
          window.location.reload();
          navigate(path);
          let path = `/dashboard/alluser-list`;
        }, 3000);
      } else {
        if (status.createQrReducer?.data?.response != null) {
          toast.error(status.createQrReducer?.data?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
    }
  }, [status?.createQrReducer]);

  return (
    <>
      <div
        className="genreteqrbg"
        alt="bg-image"
        style={{
          backgroundImage: 'url("./image/bg.png")',
          backgroundRepeat: "repeat",
          height: "100%",
          minHeight: "calc(100vh - 75.5px)",
        }}
      >
        <div className="generetetext">
          <h2>Generate Product QR code</h2>
          <p>
            Good supply chain management keeps companies out of the headlines
            and away from expensive recalls and lawsuits.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="formwarp">
              <input
                type="text"
                name="ProductName"
                placeholder="Product Name"
                value={formData.ProductName}
                onChange={handleChange}
              />
              <Box />
              {errors.ProductName && (
                <div className="error">{errors.ProductName}</div>
              )}
            </div>
            <div className="formwarp">
              <input
                type="text"
                name="sku_id"
                value={formData.sku_id}
                onChange={handleChange}
                placeholder="SKU ID"
              />
              <Box />
              {errors.sku_id && <div className="error">{errors.sku_id}</div>}
            </div>
            <div className="formwarp">
              <input
                type="number"
                name="quantity"
                value={formData.quantity}
                placeholder="Quantity"
                onChange={handleChange}
              />
              <Box />
              {errors.quantity && (
                <div className="error">{errors.quantity}</div>
              )}
            </div>
            <div className="formwarp">
              <input
                type="text"
                name="description"
                value={formData.description}
                placeholder="Product Description"
                onChange={handleChange}
              />
              <Box />
              {errors.description && (
                <div className="error">{errors.description}</div>
              )}
            </div>
            <div className="formwarp">
              <input type="file" name="imagefile" onChange={handleFileChange} />
              <DocumentUpload />
              {errors.selectedFile && (
                <div className="error">{errors.selectedFile}</div>
              )}
              <span className="upload-name">
                {selectedFile ? selectedFile.name : "Upload Product Image"}
              </span>
            </div>

            <div className="formwarp">
              <button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <img src="./image/loding.gif"></img>
                ) : (
                  "Generate QR"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddProduct;
