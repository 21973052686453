import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import "react-tabs/style/react-tabs.css";
import { ShoppingBag, TruckFast, Shop, Location } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { allOrder } from "../redux/actions/userAuth";
import { toast } from "react-toastify";
import Moment from "react-moment";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import * as XLSX from "xlsx";

const AllOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state);
  const [activeData, setActiveData] = useState("");
  const [allOrderDetails, setAllOrderDetails] = useState([]);
  const [data, setData] = useState({
    role: "",
    location: "",
    startDate: "",
    endDate: "",
  });
  const roleData = [
    { label: "Dealer", value: "dealer" },
    { label: "Retailer", value: "retailer" },
    { label: "Distributor", value: "distributor" },
  ];
  useEffect(() => {
    dispatch(
      allOrder(
        activeData,
        data.role,
        data.startDate,
        data.endDate,
        data.location
      )
    );
  }, [activeData]);
  const onChangeRole = (e) => {
    setData({
      ...data,
      role: e.target.value,
    });
    dispatch(
      allOrder(
        activeData,
        e.target.value,
        data.startDate,
        data.endDate,
        data.location
      )
    );
  };

  const onChangeLocation = (e) => {
    setData({
      ...data,
      location: e.target.value,
    });
    dispatch(
      allOrder(
        activeData,
        data.role,
        data.startDate,
        data.endDate,
        e.target.value
      )
    );
  };
  const getDataRange = (e) => {
    const startDate = moment(e[0]);
    const endDate = moment(e[1]);
    const startDateNew = startDate.format("YYYY-MM-DD");
    const endDateNew = endDate.format("YYYY-MM-DD");
    setData({
      ...data,
      startDate: startDateNew,
      endDate: endDateNew,
    });
    dispatch(
      allOrder(activeData, data.role, startDateNew, endDateNew, data.location)
    );
  };
  const clearDateRange = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
    });
    dispatch(allOrder(activeData, data.role, "", "", data.location));
  };
  const onexportToExcel = () => {
    if (allOrderDetails && allOrderDetails?.length > 0) {
      function exportToExcel(data) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "order-lists.xlsx");
      }
      exportToExcel(allOrderDetails);
    } else {
      toast.error("No data available to export", {
        position: "bottom-right",
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (status.allOrder.data !== "") {
      if (status.allOrder.data.status === 200) {
        setAllOrderDetails(status.allOrder.data.data);
      }
    } else {
      if (status.allOrder.data.response != null) {
        toast.error(
          status.allOrder.data.response.data.error
            ? status.allOrder.data.response.data.error
            : status.allOrder.data.response.data,
          {
            position: "bottom-right",
            theme: "colored",
          }
        );
      } else {
        toast.error(status.allOrder.data.message, {
          position: "bottom-right",
          theme: "colored",
        });
      }
    }
  }, [status]);
  return (
    <>
      <div className="MyOrderTab">
        <div className="filter-order order-report-f">
          <div className="input-filter formSection">
            {" "}
            <div className="formInput formInputSelect">
              <TextField
                id="filled-select-role"
                select
                label="Select Role"
                defaultValue="--Select--"
                name="role"
                SelectProps={{ native: true }}
                variant="filled"
                onChange={(e) => onChangeRole(e)}
              >
                <option key="" value=""></option>
                {roleData &&
                  roleData.map((option, key) => (
                    <option key={key} value={option.value}>
                      {" "}
                      {option.label}{" "}
                    </option>
                  ))}
              </TextField>
            </div>
          </div>
          <div className="input-filter formSection">
            <DateRangePicker
              placement="auto"
              placeholder="Select Date"
              onOk={(e) => getDataRange(e)}
              onClean={() => clearDateRange()}
            />
          </div>
          <div className="input-filter formSection">
            <div className="formInput">
              <FormControl variant="filled">
                <InputLabel htmlFor="input">Pin Code</InputLabel>
                <FilledInput
                  id="input2"
                  name="location"
                  type="number"
                  autoComplete="off"
                  onChange={(e) => onChangeLocation(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <Location />{" "}
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="input-filter formSection">
            <button type="button" onClick={onexportToExcel} className="m-0">
              Export to Excel
            </button>
          </div>
        </div>
        <div className="react-tabs" data-rttabs="true">
          <ul className="react-tabs__tab-list">
            <li
              className={` react-tabs__tab ${
                activeData === "" ? "react-tabs__tab--selected" : ""
              }`}
              onClick={() => setActiveData("")}
            >
              All Orders
            </li>
            <li
              className={` react-tabs__tab ${
                activeData === "open" ? "react-tabs__tab--selected" : ""
              }`}
              onClick={() => setActiveData("open")}
            >
              Open
            </li>
            <li
              className={` react-tabs__tab ${
                activeData === "in stock" ? "react-tabs__tab--selected" : ""
              }`}
              onClick={() => setActiveData("in stock")}
            >
              In Stock
            </li>
            <li
              className={` react-tabs__tab ${
                activeData === "in transfer" ? "react-tabs__tab--selected" : ""
              }`}
              onClick={() => setActiveData("in transfer")}
            >
              In Transfer
            </li>
            <li
              className={` react-tabs__tab ${
                activeData === "sold out" ? "react-tabs__tab--selected" : ""
              }`}
              onClick={() => setActiveData("sold out")}
            >
              Sold Out
            </li>
          </ul>
          <div className="react-tabs__tab-panel react-tabs__tab-panel--selected">
            {allOrderDetails && allOrderDetails.length === 0 ? (
              <h3 style={{ padding: "30px 0px", textAlign: "center" }}>
                Order Not Found
              </h3>
            ) : (
              allOrderDetails.map((item, index) => (
                <div
                  className={`RecentBoxInner ${
                    item.status === "open"
                      ? "green"
                      : item.status === "in stock"
                      ? "yellow"
                      : item.status === "in transfer"
                      ? "blue"
                      : "red"
                  } `}
                  key={index}
                >
                  <Link
                    to={`/dashboard/cement-bag-page?orderNo=${item.order_no}`}
                  >
                    <div className="RecentBoxInner-text">
                      <h3>
                        {item.name} - {item.role}
                      </h3>
                      <p>
                        Ref #{item.order_no} <span className="bullets"></span>{" "}
                        {item.orderCount}{" "}
                        {item.orderCount > 1 ? "items" : "item"}{" "}
                        <span className="bullets"></span>{" "}
                        <span>
                          <Moment fromNow>{item.createdAt}</Moment>
                        </span>
                      </p>
                    </div>
                    <div className="RecentBoxInner-img">
                      <span>{item.status}</span>
                      {item.status === "open" ? (
                        <TruckFast />
                      ) : item.status === "in stock" ? (
                        <Shop />
                      ) : item.status === "in transfer" ? (
                        <TruckFast />
                      ) : (
                        <ShoppingBag />
                      )}
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>

        {/* <Tabs>
                <TabList>
                    <Tab>All Orders</Tab>
                    <Tab>Open</Tab>
                    <Tab>In Stock</Tab>
                    <Tab>In Transfer</Tab>
                    <Tab>Sold Out</Tab>
                </TabList>

                <TabPanel>
                <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Raj Kumar - Dealer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 3 of 5 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img green">
                    <span>Open</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Sam Kumar - Dealer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 4 of 4 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img yellow">
                    <span>In Stock</span>
                      <Shop />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner yellow">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Prism Cement - Super Admin</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 2 of 2 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img blue">
                    <span>In Transfer</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Raj Kumar - Retailer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 3 of 5 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img green">
                    <span>Open</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner ">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Sam Kumar - Retailer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 4 of 4 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img yellow">
                    <span>In Stock</span>
                      <Shop />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner green">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Prism Cement - Admin</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 2 of 2 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img red">
                    <span>Sold Out</span>
                      <ShoppingBag />
                    </div>
                    </Link>
                </div>
                </TabPanel>
                <TabPanel>
                <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Raj Kumar - Dealer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 3 of 5 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img green">
                    <span>Open</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Raj Kumar - Retailer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 3 of 5 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img green">
                    <span>Open</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                </TabPanel>

                <TabPanel>
                 <div className="RecentBoxInner">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Sam Kumar - Dealer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 4 of 4 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img yellow">
                    <span>In Stock</span>
                      <Shop />
                    </div>
                    </Link>
                </div>
                <div className="RecentBoxInner ">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Sam Kumar - Retailer</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 4 of 4 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img yellow">
                    <span>In Stock</span>
                      <Shop />
                    </div>
                    </Link>
                </div>
                </TabPanel>
                <TabPanel>
                <div className="RecentBoxInner yellow">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Prism Cement - Super Admin</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 2 of 2 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img blue">
                    <span>In Transfer</span>
                      <TruckFast />
                    </div>
                    </Link>
                </div>
                </TabPanel>

                <TabPanel>
                <div className="RecentBoxInner green">
                <Link to="/dashboard/Cement-Bag-Page">
                    <div className="RecentBoxInner-text PaddFild">
                      <h3>Prism Cement - Admin</h3>
                      <p>Ref #12345678 <span className="bullets"></span> 2 of 2 items <span className="bullets"></span> <span>1 hour ago</span></p>
                    </div>
                    <div className="RecentBoxInner-img red">
                    <span>Sold Out</span>
                      <ShoppingBag />
                    </div>
                    </Link>
                </div>
                </TabPanel>
            </Tabs> */}
      </div>
    </>
  );
};

export default AllOrders;
