import React, { useState, useEffect } from "react";
import {
  Grid3,
  ArrowRight2,
  AddCircle,
  UserOctagon,
  TruckFast,
  Setting2,
  ArrowSquareRight,
  ArrowSquareLeft,
} from "iconsax-react";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("sidemenu_open", isOpen);
  }, [isOpen]);

  const pathname = useLocation().pathname;
  return (
    <>
      <div className="menu">
        <ul>
          <li>
            <Link
              className={pathname === "/dashboard" ? "active" : ""}
              to="/dashboard"
            >
              <Grid3 />
              <small>Dashboard</small>
              <label>Dashboard</label>
              <ArrowRight2 />
            </Link>
          </li>
          <li>
            <Link
              className={
                pathname === "/dashboard/create-new" ||
                pathname === "/dashboard/successfully-add-new"
                  ? "active"
                  : ""
              }
              to="/dashboard/create-new"
            >
              <AddCircle />
              <small>Create User</small>
              <label>Create User</label>
              <ArrowRight2 />
            </Link>
          </li>
          <li>
            <Link
              className={
                pathname === "/dashboard/manage-user" ||
                pathname === "/dashboard/view-profile" ||
                pathname === "/dashboard/user-edit-profile"
                  ? "active"
                  : ""
              }
              to="/dashboard/manage-user"
            >
              <UserOctagon />
              <small>Manage User</small>
              <label>Manage User</label>
              <ArrowRight2 />
            </Link>
          </li>
          <li>
            <Link
              className={
                pathname === "/dashboard/order-tab" ||
                pathname === "/dashboard/cement-bag-page" ||
                pathname === "/dashboard/bag-details" ||
                pathname === "/dashboard/dealer-address"
                  ? "active"
                  : ""
              }
              to="/dashboard/order-tab"
            >
              <TruckFast />
              <small>Orders Report</small>
              <label>Orders Report</label>
              <ArrowRight2 />
            </Link>
          </li>
          <li>
            <Link
              className={
                pathname === "/dashboard/alluser-list" ||
                pathname === "/dashboard/assign-qr" ||
                pathname == "/dashboard/success"
                  ? "active"
                  : ""
              }
              to="/dashboard/alluser-list"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 28 28"
                className="addQr"
              >
                <path
                  fill="#fcfcfc"
                  d="M10.75 15A2.25 2.25 0 0 1 13 17.25v5.5A2.25 2.25 0 0 1 10.75 25h-5.5A2.25 2.25 0 0 1 3 22.75v-5.5A2.25 2.25 0 0 1 5.25 15zm7.585 0v3.333h3.332v3.334h-3.332v3.332H15v-3.333h3.333v-3.333H15V15zM25 21.666V25h-3.333v-3.333zM10.75 16.5h-5.5a.75.75 0 0 0-.75.75v5.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-5.5a.75.75 0 0 0-.75-.75m-1.25 2v3h-3v-3zM25 15v3.333h-3.333V15zM10.75 3A2.25 2.25 0 0 1 13 5.25v5.5A2.25 2.25 0 0 1 10.75 13h-5.5A2.25 2.25 0 0 1 3 10.75v-5.5A2.25 2.25 0 0 1 5.25 3zm12 0A2.25 2.25 0 0 1 25 5.25v5.5A2.25 2.25 0 0 1 22.75 13h-5.5A2.25 2.25 0 0 1 15 10.75v-5.5A2.25 2.25 0 0 1 17.25 3zm-12 1.5h-5.5a.75.75 0 0 0-.75.75v5.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-5.5a.75.75 0 0 0-.75-.75m12 0h-5.5a.75.75 0 0 0-.75.75v5.5c0 .414.336.75.75.75h5.5a.75.75 0 0 0 .75-.75v-5.5a.75.75 0 0 0-.75-.75M9.5 6.5v3h-3v-3zm12 0v3h-3v-3z"
                />
              </svg>
              <small>Product QR Code</small>
              <label>Product QR Code</label>

              <ArrowRight2 />
            </Link>
          </li>
          <li>
            <Link
              className={
                pathname === "/dashboard/general-settings" ||
                pathname === "/dashboard/change-password" ||
                pathname === "/dashboard/edit-profile"
                  ? "active"
                  : ""
              }
              to="/dashboard/general-settings"
            >
              <Setting2 />
              <small>Settings</small>
              <label>Settings</label>
              <ArrowRight2 />
            </Link>
          </li>
          {/* <li>
            <Link
              className={pathname === "/dashboard/all-product" ? "active" : ""}
              to="/dashboard/all-product"
            >
              <Setting2 />
              <small>ProductQrCode</small>
              <label>ProductQrCode</label>

              <ArrowRight2 />
            </Link>
          </li> */}
          {/* <li>
            <Link
              className={pathname === "/dashboard/qr-code" ? "active" : ""}
              to="/dashboard/qr-code"
            >
              <Setting2 />
              <small>QrCode</small>
              <label>QrCode</label>

              <ArrowRight2 />
            </Link>
          </li> */}

          <li>
            <div className="bars">
              <Link
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <ArrowSquareLeft />
                <ArrowSquareRight />
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;
