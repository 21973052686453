import React, { useEffect, useState } from "react";
import { Location, MoreSquare, TickCircle } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { orderDetails } from "../redux/actions/userAuth";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Moment from "react-moment";

const CementBag = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const [allOrderDetails, setAllOrderDetails] = useState();

  let param = window.location.href;
  param = param?.split("?")[1]?.split("=")[1];
  //console.log(param);

  useEffect(() => {
    const apiParams = { param };
    dispatch(orderDetails(apiParams));
  }, [param]);
  useEffect(() => {
    if (status.orderDetails.data !== "") {
      if (status.orderDetails.data.status === 200) {
        setAllOrderDetails(status.orderDetails.data.data);
      }
    } else {
      if (status.orderDetails.data.response != null) {
        toast.error(
          status.orderDetails.data.response.data.error
            ? status.orderDetails.data.response.data.error
            : status.orderDetails.data.response.data,
          {
            position: "bottom-right",
            theme: "colored",
          }
        );
      } else {
        toast.error(status.orderDetails.data.message, {
          position: "bottom-right",
          theme: "colored",
        });
      }
    }
  }, [status.orderDetails]);

  console.log("allOrderDetails--", allOrderDetails);
  const openItemCount = allOrderDetails?.items.filter(
    (item) => item.order_status === "open"
  ).length;
  return (
    <>
      <div className="cementBag">
        <div className="cementext">
          <h2>
            {allOrderDetails?.userName} - {allOrderDetails?.role}
            <Link to="/"></Link>
          </h2>
          <p>
            Ref #{allOrderDetails?.orderedId}
            <span className="bullets"></span> {allOrderDetails?.items.length}{" "}
            {allOrderDetails?.items.length > 1 ? "items" : "item"}{" "}
            <span className="bullets"></span>{" "}
            <Moment fromNow>{allOrderDetails?.createdAt}</Moment>
          </p>
        </div>
        <div className="ShippingAddress">
          <div className="cementextLeft">
            <p>Current Address</p>
            <h3>{allOrderDetails?.userAddress}</h3>
          </div>
          <div className="cementextLeft">
            <p>Shipping Address</p>
            <h3>
              <b>
                {allOrderDetails?.userName} - {allOrderDetails?.role}
              </b>
              {/* <span>
                <Link to="/dashboard/dealer-address">
                <Link to="#">
                  <Location />
                  <br />
                  TRACK
                </Link>
              </span>{" "} */}
              <br />
              {allOrderDetails?.shippingAddress}
            </h3>
          </div>
        </div>
        <div className="Scanitems">
          <h2>
            Scan items <span className="bullets"></span>{" "}
            <b>
              Left {openItemCount} out of {allOrderDetails?.items.length}
            </b>
          </h2>
          <p>Order auto move to In Stock after all items are scanned</p>
        </div>

        {allOrderDetails?.items
          ?.sort((a) => {
            if (a.order_status === "open") {
              return -1;
            } else {
              return 0;
            }
          })
          .map((innerItem, ind) => {
            return (
              <div className="cementIn" key={ind}>
                <Link to="#">
                  <div className="cementextLeft">
                    <h3>QR #{innerItem?.qr_id}</h3>
                    <p>
                      {innerItem?.order_status === "open" ? (
                        <span className="green">Open</span>
                      ) : innerItem?.order_status === "in stock" ? (
                        <span className="yellow">In Stock</span>
                      ) : innerItem?.order_status === "in transfer" ? (
                        <span className="blue">In Transfer</span>
                      ) : (
                        <span className="red">Sold Out</span>
                      )}{" "}
                      <span className="bullets"></span>{" "}
                      <Moment fromNow>{innerItem?.scanned_at}</Moment>
                    </p>
                  </div>
                </Link>
                <div className="cementextRight">
                  {innerItem?.order_status === "in stock" ? (
                    <label>
                      <button className="green">
                        <img src="../image/scaned.svg" alt="scaned" />
                        <br />
                        <span>Scanned</span>
                      </button>
                    </label>
                  ) : (
                    ""
                  )}
                  <label>
                    {/* <Link to={`/dashboard/bag-details?order-id=${param}`}> */}
                    <Link
                      to={`/dashboard/Qr?id=${innerItem?.qr_id}`}
                      data-value={innerItem?.id}
                    >
                      <button
                        className="FirstBTncolor"
                        onClick={() =>
                          window.sessionStorage.setItem("oder-id", param)
                        }
                      >
                        <MoreSquare />
                        <br />
                        <span>VIEW</span>
                      </button>
                    </Link>{" "}
                  </label>
                </div>
              </div>
            );
          })}

        {/* <div className="cementIn">
          <Link to="/dashboard/bag-details">
            <div className="cementextLeft">
              <h3>QR #1241</h3>
              <p>
                <span className="red">Discrepancy</span>{" "}
                <span className="bullets"></span> 1 hour ago
              </p>
            </div>
          </Link>
          <div className="cementextRight">
            <label>
              <Link to="/dashboard/bag-details">
                <button className="FirstBTncolor">
                  <MoreSquare />
                  <br />
                  <span>VIEW</span>
                </button>
              </Link>{" "}
            </label>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CementBag;
