// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom404.css */

.custom404-container {
    text-align: center;
}

.notFound {
    font-size: 24px;
    margin-bottom: 20px;
    color: #666666;
    font-weight: 500;
    line-height: 29px;
}

.dashLink {
    background: #A94545;
    border-radius: 12px;
    width: auto;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    display: inline-block;
    padding: 12px 25px;
}
.dashLink:hover {
    color: #fff;
}
@media (max-width: 576px) {
    h1 img {
        max-width: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Css/Custom404.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["/* Custom404.css */\n\n.custom404-container {\n    text-align: center;\n}\n\n.notFound {\n    font-size: 24px;\n    margin-bottom: 20px;\n    color: #666666;\n    font-weight: 500;\n    line-height: 29px;\n}\n\n.dashLink {\n    background: #A94545;\n    border-radius: 12px;\n    width: auto;\n    border: none;\n    color: #fff;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 30px;\n    display: inline-block;\n    padding: 12px 25px;\n}\n.dashLink:hover {\n    color: #fff;\n}\n@media (max-width: 576px) {\n    h1 img {\n        max-width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
