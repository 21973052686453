import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, Box, Location } from "iconsax-react";
import { auth } from "../config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createQr } from "../redux/actions/userAuth";

const AllProduct = () => {
  const navigate = useNavigate();
  const status = useSelector((state) => state);
  const [formData, setFormData] = useState({
    sku_id: "",
    batchNo: "",
    location: "",
    quantity: "",
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name == "quantity" ? parseInt(value) : value,
    }));
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!formData.sku_id) {
      validationErrors.sku_id = "skuId is required";
    }
    if (!formData.batchNo) {
      validationErrors.batchNo = "BatchNo is required";
    }
    if (!formData.location) {
      validationErrors.location = "Location is required";
    }
    if (!formData.quantity) {
      validationErrors.quantity = "Quantity is required";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      dispatch(createQr(formData));
      // let path = `/dashboard/alluser-list`;
      // navigate(path);
    }
  };
  useEffect(() => {
    if (status.createQrReducer?.data !== "") {
      if (status.createQrReducer?.data?.status === 200) {
        toast.success(status.createQrReducer?.data?.data?.message, {
          position: "top-right",
          theme: "colored",
        });
        setTimeout(() => {
          let path = `/dashboard/alluser-list`;
          navigate(path);
          window.location.reload();
        }, 3000);
      } else {
        if (status.createQrReducer?.data?.response != null) {
          toast.error(status.createQrReducer?.data?.response?.data?.error, {
            position: "top-right",
            theme: "colored",
          });
        }
      }
    }
  }, [status?.createQrReducer]);

  return (
    <>
      <div
        className="genreteqrbg"
        alt="bg-image"
        style={{
          backgroundImage: 'url("./image/bg.png")',
          backgroundRepeat: "repeat",
          height: "100%",
          minHeight: "calc(100vh - 75.5px)",
        }}
      >
        <div className="generetetext">
          <h2>Generate Product QR code</h2>
          <p>
            Good supply chain management keeps companies out of the headlines
            and away from expensive recalls and lawsuits.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="formwarp">
              <input
                type="text"
                name="sku_id"
                value={formData.sku_id}
                onChange={handleChange}
                placeholder="SKU ID"
                autoComplete="off"
              />
              <Box />
              {errors.sku_id && <div className="error">{errors.sku_id}</div>}
            </div>
            <div className="formwarp">
              <input
                type="text"
                name="batchNo"
                placeholder="Batch No."
                value={formData.batchNo}
                onChange={handleChange}
                autoComplete="off"
              />
              <Box />
              {errors.batchNo && <div className="error">{errors.batchNo}</div>}
            </div>
            <div className="formwarp">
              <input
                type="text"
                name="location"
                placeholder="Location"
                value={formData.location}
                onChange={handleChange}
                autoComplete="off"
              />
              <Location />
              {errors.location && (
                <div className="error">{errors.location}</div>
              )}
            </div>
            <div className="formwarp">
              <input
                type="number"
                name="quantity"
                placeholder="Quantity"
                value={formData.quantity}
                onChange={handleChange}
                autoComplete="off"
              />
              <Box />
              {errors.quantity && (
                <div className="error">{errors.quantity}</div>
              )}
            </div>

            <div className="formwarp">
              <button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <img src="./image/loding.gif"></img>
                ) : (
                  "Generate QR"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AllProduct;
