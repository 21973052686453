// Custom404.js
import React from "react";
import { Link } from "react-router-dom";
import "../Css/Custom404.css";

const Custom404 = () => {
  return (
    <div className="custom404-container">
      <h1>
        <img src="../image/page404.svg" alt="page 404" />
      </h1>
      <p className="notFound">Oops! Page Not Found</p>
      <Link to="/dashboard" className="dashLink">
        Go Back
      </Link>
    </div>
  );
};

export default Custom404;
