import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { qrCode, scanDetails } from "../redux/actions/userAuth";
import { toast } from "react-toastify";
import Base64Downloader from "react-base64-downloader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Qr = ({ props, route, navigation }) => {
  const [imageData, setImageData] = useState([]);
  const [scanDetail, setScanDetail] = useState([]);
  const [letlong, setLetlong] = useState([]);
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let param = window.location.href;
  const productid = param?.split("?")[1]?.split("=")[1];
  console.log("productid", productid);
  // useEffect(() => {
  //   const apiData = {};
  //   dispatch(qrCode(apiData));
  // }, []);

  useEffect(() => {
    // Call the action with the dynamic productId
    dispatch(qrCode(productid));
    dispatch(scanDetails(productid));
  }, [productid, dispatch]);

  useEffect(() => {
    if (status.qrCode.data !== "") {
      if (status.qrCode.data.status === 200) {
        setImageData(status.qrCode.data.data);
      }
    }
    if (status.scanDetails.data !== "") {
      if (status.scanDetails.data.status === 200) {
        setScanDetail(status.scanDetails.data.data);
      }
    }
  }, [status]);

  return (
    <>
      <div
        className="bg"
        style={{
          backgroundImage: 'url("./image/bg.png")',
          backgroundRepeat: "repeat",
          height: "100%",
          minHeight: "calc(100vh - 75.5px)",
        }}
      >
        <div className="generetetext">
          <h2>Your Product QR code</h2>
          <p>
            Good supply chain management keeps companies out of the headlines
            and away from expensive recalls and lawsuits.
          </p>

          <div className="Qr">
            <div className="idflex btop qrspacing">
              <>
                <div className="idflexleft">
                  <img src={`data:image/jpeg;base64,${imageData.QRCode}`} />
                </div>
              </>
            </div>
          </div>

          <div className="borderline"></div>
          <div>
            <h6 className="pt-4"></h6>
          </div>

          <div className="qrbtn">
            <a href="#">Print</a>
            {/* <a href="#">Download QR</a> */}
            <Base64Downloader
              base64={`data:image/jpeg;base64,${imageData.QRCode}`}
              downloadName={productid}
            >
              Download QR
            </Base64Downloader>
          </div>
          <div className="geolocation-section">
            <h3>{scanDetail?.length > 0 ? "Your Product Journey" : ""}</h3>
            <div className="geolocationtext">
              <ul className="scaned-history">
                {scanDetail &&
                  scanDetail
                    // .sort((a, b) => {
                    //   const dateA = new Date(a.ScannedAt);
                    //   const dateB = new Date(b.ScannedAt);
                    //   return dateB - dateA;
                    // })
                    //.reverse()
                    .map((scanD, key) => {
                      const dateObject = new Date(scanD.ScannedAt);
                      const formattedDate = dateObject.toLocaleDateString();
                      const a = scanD?.Location;
                      const addess = a?.split(">")[1];
                      return (
                        <li key={key}>
                          <div>
                            <p>
                              {key === 0
                                ? "Left Manufacturer Facility"
                                : key === 1
                                ? "Reached Dealer Facility"
                                : key === 2
                                ? "Left Dealer Facility"
                                : key === 3
                                ? "Reached Distributor Facility"
                                : key === 4
                                ? "Left Distributor Facility"
                                : key === 5
                                ? "Reached Retailer Facility"
                                : ""}{" "}
                              <span className="bullets"></span> {formattedDate}{" "}
                            </p>
                            <p>
                              <b>Address - </b>
                              {addess}
                            </p>
                            <p className="polygon">
                              <Link target="_blank" to={scanD.ScannedAddress}>
                                {scanD.ScannedAddress}
                              </Link>
                            </p>
                          </div>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Qr;
